<template>
    <div>
        <topBox :title="title" />
        <!-- main -->
        <div class="h-[calc(100vh-50px)] pt-[50px] pb-[50px] overflow-y-auto bg-local">
            <div class="p-3">

                <!-- Card 4 -->
                <div class="rounded-sm w-full grid grid-cols-12 bg-white shadow p-3 gap-2 items-center hover:shadow-lg transition delay-150 duration-300 ease-in-out hover:scale-105 transform">
                    
                    <!-- Title -->
                    <div class="col-span-11">
                        <p class="text-slate-500 font-semibold text-xs-xs"> {{ $t('revision.withdrawalAmount') }} </p>
                    </div>

                    <div class="col-span-12">
                        <input v-model="input.amount" :class="css.input" :placeholder="$t('placeholder.withdrawalAmount')" />
                    </div>
                    
                    <!-- Description -->
                    <div class="md:col-start-2 col-span-11">
                        <p class="text-xs-xs text-slate-400"> {{$t('text.AccountBalance')}}：VND {{ api.balance }}  </p>
                    </div>

                </div><!-- /End Card 4 -->

                <!-- Card 1 -->
                <div class="rounded-sm w-full grid grid-cols-12 bg-white shadow p-3 gap-2 items-center hover:shadow-lg transition delay-150 duration-300 ease-in-out hover:scale-105 transform" >
                    
                        <!-- Row Left -->
                        <div class="col-span-4  text-left text-xs-xs text-slate-400">
                            {{ $t('revision.realName') }} 
                        </div>
                        <!-- Row Right -->
                        <div class="col-span-8 text-right text-xs-xs">
                            {{  api.real_name }}
                        </div>

                        <!-- Row Left -->
                        <div class="col-span-4  text-left text-xs-xs text-slate-400">
                            {{ $t('revision.userNAME') }} 
                        </div>
                        <!-- Row Right -->
                        <div class="col-span-8  text-right text-xs-xs">
                            {{  api.bank_user }}
                        </div>

                        <!-- Row Left -->
                        <div class="col-span-4  text-left text-xs-xs text-slate-400">
                            {{ $t('revision.bankAccount') }} 
                        </div>
                        <!-- Row Right -->
                        <div class="col-span-8  text-right text-xs-xs">
                            {{  api.bank_card }}
                        </div>
                
                        <!-- Row Left -->
                        <div class="col-span-4  text-left text-xs-xs text-slate-400">
                            {{ $t('revision.bankName') }} 
                        </div>
                        <!-- Row Right -->
                        <div class="col-span-8  text-right text-xs-xs">
                            {{  api.bank }}
                        </div>
                    
                </div><!-- /End Card 1 -->

                


                <!-- Card 4 -->
                <div class="rounded-sm w-full grid grid-cols-12 bg-white shadow p-3 gap-2 items-center hover:shadow-lg transition delay-150 duration-300 ease-in-out hover:scale-105 transform">
                    
                    <!-- Title -->
                    <div class="col-span-11">
                        <p class="text-slate-500 font-semibold text-xs-xs"> {{ $t('title.FundPassword') }} </p>
                    </div>

                    <div class="col-span-12">
                        <input type="password" v-model="input.pass_pay"  :class="css.input" :placeholder="$t('placeholder.FundPassword')" />
                    </div>
                    
                    <!-- Description -->
                    <div class="md:col-start-2 col-span-11">
                        <p class="text-xs-xs text-slate-400"> {{$t('text.withdrawalMin')}}  </p>
                        <p class="text-xs-xs text-slate-400"> {{$t('text.withdrawalMax')}}  </p>
                    </div>

                </div><!-- /End Card 4 -->

                <div class="mt-0 p-4">
                    <button :class="css.but" v-show="sendBut" @click="onSubmit">{{ $t('but.continue') }}</button>
                </div>

            </div>
        </div>
        <!-- /end main -->
        <mainMenu />  
    </div>
</template>

<script>
import { useI18n } from 'vue-i18n'
import { layer } from "vue3-layer"
import topBox from "../components/TopBox.vue"
import mainMenu from "../components/MainMenu.vue"
import { ref } from 'vue'
import axios from 'axios'
import postPages from "@/assets/js/postPages"

export default {
    components: {
        topBox,
        mainMenu
    },
    setup() {
        // 向子组件传递的参数
        const title = ref( 'x' )  
        return {
            title
        }
    },
    data() {
        return {
            lang: '',
            sendBut: true,
            css: {
                h1: 'text-xs-xs pl-5 font-semibold text-slate-700 text-left',
                input: 'block w-full px-5 py-2 border rounded-lg bg-white shadow-lg placeholder-gray-400 text-gray-700 focus:ring focus:outline-none  text-xs-xs',
                but: 'submit-button text-xs text-white px-1 py-2 rounded-full bg-orange-500 text-blue-900 focus:ring focus:outline-none w-full text-xl font-semibold transition-colors',
            },
            banks: [],
            api: {
                real_name: '',
                bank_id: '',
                bank_card: '',
                bank_user: '',
                bank: '',
                balance: '',
            },
            input: {
                amount: '',
                pass_pay: '',
            }
        }
    },
    mounted() {
        // 切换语言
        const { locale } = useI18n()  // 从国际化中取出配置项 locale 因为初始化时 用户没有指定语种 要使用系统默认的语种
        this.lang = localStorage.getItem("lang") ? localStorage.getItem("lang") : locale.value // 如果用户有选择特定语种 就使用用户指定的 不然就使用系统默认的
        locale.value = this.lang

        this.title = this.$t('page.withdraw') // 重置 setup 中的 title 要在重设置了语言之后
        
        this.getInfo()
        return {}
    },
    methods: {
        getSelect()
        {
            // 获取供选择的数据项
            // 如何打包搜索参数？  &p=${p}
            this.searchParams = `&bank_id=${this.api.bank_id}`
            const page = postPages(this.lang, localStorage.getItem('token'), '/api/m/name', this.ps, this.p, this.searchParams)
            // 将异步获取的数据导入 VM 数据
            page.data.then(res =>{
                // console.log(res)
                // const data = toRaw(res)
                this.api.bank = res.data.name
            })
        },
        
        // 得到这个用户的基本信息
        getInfo()
        {
            // console.log('得到这个用户的基本信息')
            axios.post(
                '/api/m/info/user', 
                {
                },
                {
                    headers: {
                        // 'Content-Type': 'multipart/form-data'
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Accept-Language': this.lang,
                        'token': localStorage.getItem('token')
                    }
                }
            )
            .then((response) =>  {
                console.log(response.data.data)
                if (0===response.data.code) {
                    this.api.real_name = response.data.data.real_name
                    this.api.bank_id = response.data.data.bank_id
                    this.api.bank_card = response.data.data.bank_card
                    this.api.bank_user = response.data.data.bank_user
                    this.api.balance = response.data.data.balance
                    this.getSelect()

                } else {
                    // let word = response.data.data.id
                    // let key = word.charAt(0).toUpperCase() + word.slice(1)
                    // // 转成国际化 后端转提示语 前端转表单名称
                    // let msg = this.$t(`input.${key}`) + ' ' + response.data.msg 
                    layer.msg(response.data.msg)
                }
            })
            .catch(function (error) {
                console.log(error)
            })
        },// End 得到这个用户的基本信息
        // 提交提现申请
        onSubmit() {
            // 点击提交时 会向后端发起创建充值订单的请求 服务器响应慢时 用户可能会多次点击 所以 当用户发起充值请求时 隐藏掉这个充值按钮
            this.sendBut = false
            axios.post(
                '/api/m/make/withdraw', 
                {
                    amount: this.input.amount,
                    pass_pay: this.input.pass_pay,
                },
                {
                    headers: {
                        // 'Content-Type': 'multipart/form-data'
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Accept-Language': this.lang,
                        'token': localStorage.getItem('token')
                    }
                }
            )
            .then((response) =>  {
                if (0===response.data.code) {
                    layer.msg(response.data.msg)
                    this.$router.push({
                        path: '/user',
                        query: {
                            msg: ''
                        }
                    })
                } else {
                    layer.msg( this.$t('input.'+response.data.data.id) + ' ' + response.data.msg)
                    this.sendBut = true
                }
                this.getData()
            })
            .catch(function (error) {
                console.log(error)
            })
        } // End onSubmit()

    }
}
</script>

<style>
.top-box-bg {
  background-image: url('/public/upload/600x55.png');
  background-repeat: no-repeat; /** 图片不要平铺 */
  background-size: 100% 55px; /** 因为用大一倍的图，才够清晰，但是如果不定义自适应，只能显示一半的图片。所以宽度给 100% 但高度要给它限制 */
}
</style>