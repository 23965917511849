<template>
  <transition name="fade">
    <div
      class="v-message w-[calc(100vw-12vw)]"
      :class="type"
      v-if="showMessage"
    >
      {{ msg }}
    </div>
  </transition>
</template>

<script>
import { ref } from 'vue'

export default {
  setup () {
    const msg = ref('test')
    const showMessage = ref(false)
    const type = ref('')

    return {
      msg,
      showMessage,
      type
    }
  }
}
</script>

<style scoped>
.v-message {
  position: fixed;
  top: 40px;
  left: 0;
  right: 0;
  z-index: 999;
  margin: auto;
  padding: 10px 15px;
  border-radius: 5px;
  /* width: fit-content; */
  font-size: 14px;
  box-shadow: 0 3px 5px #333;
  transition: .3s;
}

.v-message.success {
  color: #fff;
  background: #5adb5a;
}
.v-message.error {
  color: #fff;
  background: #e83b3b;
}

.fade-enter-active,
.fade-leave-active {
  transition: .2s ease-in-out;
}

.fade-enter-from,
.fade-leave-to {
  transform: translateY(-50px);
  opacity: 0;
}
</style>
