<template>
    <div>
        <topMenu />
        <div class="h-[calc(100vh-50px)] pt-9" id="loginBox">
            <main class="mt-4 p-4">
                
                <div class="">
                    <div class="my-5">
                        <h1 class="text-xs font-semibold text-white text-left">{{ $t('title.account') }}</h1>
                    <input
                        type="text"
                        class="block w-full px-5 py-2 border rounded-lg bg-white shadow-lg placeholder-gray-400 text-gray-700 focus:ring focus:outline-none text-xs-xs"
                        :placeholder="$t('placeholder.account')"
                        v-model="input.account"
                    />
                    </div>
                    <div class="my-3">
                        <h1 class="text-xs font-semibold text-white text-left">{{ $t('title.password') }}</h1>
                    <input
                        type="password"
                        class="block w-full px-5 py-2 border rounded-lg bg-white shadow-lg placeholder-gray-400 text-gray-700 focus:ring focus:outline-none  text-xs-xs"
                        :placeholder="$t('placeholder.password')"
                        v-model="input.password"
                    />
                    </div>
                </div>
                </main>
                <div class="mt-0 p-4">
                    <button
                        class="submit-button px-1 py-1 rounded-full bg-blue-300 text-blue-900 focus:ring focus:outline-none w-full text-xl font-semibold transition-colors"
                        x-bind:disabled="!isValid"
                        @click="onSubmit"
                    >
                        {{ $t('but.login') }}
                    </button>
                </div>

                
                <div class="text-xs-xs">
                    <router-link to="/register">
                        <div>{{ $t('but.register') }}</div>
                    </router-link>
                </div>

        </div>
        <mainMenu />
    </div>
</template>

<script>
import { useI18n } from 'vue-i18n'
import topMenu from "../components/TopMenu.vue"
import mainMenu from "../components/MainMenu.vue"
import axios from 'axios'
import { layer } from "vue3-layer"
export default {
    components: {
        topMenu,
        mainMenu
    },
    data() {
        return {
            lang: '',
            input: {
                account: '',
                password: '',
            }
        }
    },
    mounted() {
        // 切换语言
        const { locale } = useI18n()  // 从国际化中取出配置项 locale 因为初始化时 用户没有指定语种 要使用系统默认的语种
        this.lang = localStorage.getItem("lang") ? localStorage.getItem("lang") : locale.value // 如果用户有选择特定语种 就使用用户指定的 不然就使用系统默认的
        locale.value = this.lang

        return {}
    },
    methods: {
        onSubmit() {
            // console.log('登录')
            axios.post(
                '/api/login', 
                {
                    account: this.input.account,
                    passkey: this.input.password
                },
                {
                    headers: {
                        // 'Content-Type': 'multipart/form-data'
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Accept-Language': this.lang,
                        'token': localStorage.getItem('token')
                    }
                }
            )
            .then((response) =>  {
                // console.log(response.data)
                if (0===response.data.code) {
                    localStorage.setItem('token', response.data.data.token)
                    // 跳转
                    this.$router.push({
                        path: '/user',
                        query: {
                            msg: 'reg account'
                        }
                    })
                } else {
                    // let word = response.data.data.id
                    // let key = word.charAt(0).toUpperCase() + word.slice(1)
                    // 转成国际化 后端转提示语 前端转表单名称
                    // let msg = this.$t(`input.${key}`) + ' ' + response.data.msg 
                    layer.msg(response.data.msg )
                }
            })
            .catch(function (error) {
                console.log(error)
            })
        }
    }
}
</script>

<style lang="scss" scoped>
    #loginBox {
        background: linear-gradient(270deg, #f7395f 0%, #fe702e 100%); 
        // background-image: url('/public/upload/6x4.png');
        // background-repeat: no-repeat;
    }

    .form-select {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%239ca3af' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
        background-repeat: no-repeat;
        background-position: right 0.5rem center;
        background-size: 1.5em 1.5em;
        -webkit-tap-highlight-color: transparent;
      }

      .submit-button:disabled {
        cursor: not-allowed;
        background-color: #D1D5DB;
        color: #111827;
      }

      .submit-button:disabled:hover {
        background-color: #9CA3AF;
      }

      .credit-card {
        max-width: 420px;
        margin-top: 3rem;
      }

      @media only screen and (max-width: 420px)  {
        .credit-card .front {
          font-size: 100%;
          padding: 0 2rem;
          bottom: 2rem !important;
        }

        .credit-card .front .number {
          margin-bottom: 0.5rem !important;
        }
      }

</style>