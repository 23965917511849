<template>
    <div>
        <topBox :title="title" />
        <!-- main -->
        <div class="h-[calc(100vh-50px)] pt-[50px] pb-[50px] overflow-y-auto  bg-local">
            
        </div>
        <!-- /end main -->
        <mainMenu />  
    </div>
</template>

<script>
import { useI18n } from 'vue-i18n'
import topBox from "../components/TopBox.vue"
import mainMenu from "../components/MainMenu.vue"
import { ref } from 'vue'

export default {
    components: {
        topBox,
        mainMenu
    },
    setup() {
        // 向子组件传递的参数
        const title = ref( 'x' )  
        return {
            title
        }
    },
    data() {
        return {
            lang: ''
        }
    },
    mounted() {
        
        // 切换语言
        const { locale } = useI18n()  // 从国际化中取出配置项 locale 因为初始化时 用户没有指定语种 要使用系统默认的语种
        this.lang = localStorage.getItem("lang") ? localStorage.getItem("lang") : locale.value // 如果用户有选择特定语种 就使用用户指定的 不然就使用系统默认的
        locale.value = this.lang

        this.title = this.$t('page.financialDetails') // 重置 setup 中的 title 要在重设置了语言之后
        return {}
    }
}
</script>

<style>
.top-box-bg {
  background-image: url('/public/upload/600x55.png');
  background-repeat: no-repeat; /** 图片不要平铺 */
  background-size: 100% 55px; /** 因为用大一倍的图，才够清晰，但是如果不定义自适应，只能显示一半的图片。所以宽度给 100% 但高度要给它限制 */
}
</style>