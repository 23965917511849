<template>
    <div>
        <topBox :title="title" />
        <!-- main -->
        <div class="h-[calc(100vh-50px)] pt-[50px] pb-[50px] overflow-y-auto  bg-local">
            <div class="w-[calc(100vw-12vw)] mx-auto  text-black">
                <!-- main box -->
                <div class="my-10 text-left  text-xs text-slate-900">

                    <div class="my-1">
                        <h1 class="text-xs-xs pl-5 font-semibold text-slate-700 text-left">{{ $t('revision.currentPassword') }}</h1>
                        <input 
                            type="text"
                            class="block w-full px-5 py-2 border rounded-lg bg-white shadow-lg placeholder-gray-400 text-gray-700 focus:ring focus:outline-none  text-xs-xs"
                            placeholder=""
                            v-model="input.pass_pay"
                        />
                        
                    </div>

                    <div class="text-right text-xs-xs text-slate-400 pr-4">
                        <span class="text-center text-xs-xs text-slate-400">{{ $t('placeholder.firstSettingFund') }}</span>
                    </div>

                    <div class="my-5">
                        <h1 class="text-xs-xs  pl-5 font-semibold text-slate-700 text-left">{{ $t('revision.aNewPassword') }}</h1>
                        <input
                            type="password"
                            class="block w-full px-5 py-2 border rounded-lg bg-white shadow-lg placeholder-gray-400 text-gray-700 focus:ring focus:outline-none  text-xs-xs"
                            placeholder=""
                            v-model="input.new"
                        />
                    </div>

                    <div class="my-5">
                        <h1 class="text-xs-xs  pl-5 font-semibold text-slate-700 text-left">{{ $t('revision.confirmYourPassword') }}</h1>
                        <input
                            type="password"
                            class="block w-full px-5 py-2 border rounded-lg bg-white shadow-lg placeholder-gray-400 text-gray-700 focus:ring focus:outline-none  text-xs-xs"
                            placeholder=""
                            v-model="input.confirm"
                        />
                    </div>

                    <div class="mt-0 p-4">
                        <button
                            class="submit-button text-xs text-white px-1 py-2 rounded-full bg-orange-500 text-blue-900 focus:ring focus:outline-none w-full text-xl font-semibold transition-colors"
                            @click="onSubmit"
                        >
                            {{ $t('but.continue') }}
                        </button>
                    </div>


                </div>
                <!-- /end main box -->
            </div>
        </div>
        <!-- /end main -->
        <mainMenu />  
    </div>
</template>

<script>
import axios from 'axios'
import { layer } from "vue3-layer"
import { useI18n } from 'vue-i18n'
import topBox from "../components/TopBox.vue"
import mainMenu from "../components/MainMenu.vue"
import { ref } from 'vue'

export default {
    components: {
        topBox,
        mainMenu
    },
    setup() {
        // 向子组件传递的参数
        const title = ref( 'x' )  
        return {
            title
        }
    },
    data() {
        return {
            lang: '',
            input: {
                pass_pay: '',
                new: '',
                confirm: '',
            }
        }
    },
    mounted() {
        // 切换语言
        const { locale } = useI18n()  // 从国际化中取出配置项 locale 因为初始化时 用户没有指定语种 要使用系统默认的语种
        this.lang = localStorage.getItem("lang") ? localStorage.getItem("lang") : locale.value // 如果用户有选择特定语种 就使用用户指定的 不然就使用系统默认的
        locale.value = this.lang
        // 设置页头
        this.title = this.$t('page.passwordFund') // 重置 setup 中的 title 要在重设置了语言之后
        return {}
    },
    methods: {
        onSubmit() {
            axios.post(
                '/api/m/edit/fund', 
                {
                    pass_pay: this.input.pass_pay,
                    new: this.input.new,
                    confirm: this.input.confirm,
                },
                {
                    headers: {
                        // 'Content-Type': 'multipart/form-data'
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Accept-Language': this.lang,
                        'token': localStorage.getItem('token')
                    }
                }
            )
            .then((response) =>  {
                if (0===response.data.code) {
                    layer.msg(response.data.msg)
                    
                } else {
                    layer.msg( this.$t('input.'+response.data.data.id) + ' ' + response.data.msg)
                }
                this.getData()
            })
            .catch(function (error) {
                console.log(error)
            })
        } // End onSubmit()
    } // End methods{...}
}
</script>

<style>
.top-box-bg {
  background-image: url('/public/upload/600x55.png');
  background-repeat: no-repeat; /** 图片不要平铺 */
  background-size: 100% 55px; /** 因为用大一倍的图，才够清晰，但是如果不定义自适应，只能显示一半的图片。所以宽度给 100% 但高度要给它限制 */
}
</style>