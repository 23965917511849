export default {
    page: {
        order: 'Order',
        revision: 'revision',
        guide: 'guide',
        serve: 'Serve',
        companyProfile: 'Company Profile',
        onlineService: 'online service',
        financialDetails: 'Financial Details',
        recharge: 'Recharge',
        withdraw: 'Withdraw',
        accountsCanBeReceived: 'Accounts can be received',

    },
    side: {
        main: '首页',
        trade: '交易管理',
        tradeOrder: '订单列表',
        tradeRecharge: '充值管理',
        tradeWithdraw: '提现管理',
        tradeControl: '交易控制',
        tradeAmount: '金额调整',
        tradeStatistics: '统计报表',
        tradeReport: '代理报表',
        tradeReportDaily: '代理日报表',
        tradeReportMonthly: '代理月报表',
        commodity: '商品管理',
        commodityList: '商品列表',
        commoditySort: '商品分类',
        member: '会员管理',
        memberList: '会员列表',
        memberSort: '会员等级',
        memberLevel: '会中层级',
        content: '内容管理',
        contentNotice: '公告管理',
        contentText: '文本管理',
        contentAd: '首页轮播图',
        contentLogo: 'LOGO',
        power: '权限管理',
        powerList: '访问权限',
        powerAdmin: '系统用户',
        system: '系统管理',
        systemMenu: '系统菜单管理',
        systemParameter: '系统参数配置',
        systemLog: '系统操作日志'
    },
    but: {
        login: 'Login',
        register: 'Register',
        continue: 'Continue',
        recharge: 'Recharge',
        withdraw: 'Withdraw',
    },
    footer: {
        homePage: 'Home',
        product: 'Product',
        mine: 'Mine'
    },
    title: {
        account: 'Username',
        password: 'Password',
        confirmPassword: 'Confirm password',
        inviteCode: 'Invite code',
    },
    placeholder: {
        account: 'Username (combination of numbers and letters)',
        password: 'please enter your password',
        agree: 'I agree',
        userAgreement: 'User Agreement',
        user: 'Vui lòng nhập số điện thoại của bạn',
        confirmPassword: 'Please re-enter your password',
        invitation: 'Enter the invitation code',
        rechargeAmount: 'Enter the recharge amount',
    },
    text: {
        workingCapital: 'Working Capital',
        quickAction: 'Quick Action',
        amountOfWorkingCapital: 'Amount of working capital',
        currentFrozenBalance: 'Current frozen balance',
        orderToday: 'Order today',
        roseToday: 'Rose today',
        allOrders: 'All orders',
        totalCommission: 'Total commission',
        order: 'Order',
        revision: 'revision',
        guide: 'guide',
        serve: 'Serve',
        companyProfile: 'Company Profile',
        onlineService: 'online service',
        financialDetails: 'Financial Details',
        rechargeAnnouncement: 'Note: Because the amount of deposit information is too large, you must carefully check your account card number before depositing. This platform changes account numbers from time to time. If you have any questions, please click online customer service！',
        rechargeLimit: 'Online deposit1 (50k to 300 million)',
        depositMethodSelect: 'Choose a deposit method',
        depositMethod: 'deposit method',
        serveText: 'Congratulations, you have successfully logged in!'
    },
    guideText: {
        no1: '- Online shopping brings convenience, initiative, diverse choices, with support services for purchasing, sales, safe payment, professional delivery, Pinggo.vn constantly strives to efforts and improvements to meet consumer shopping needs.',
        no2: '- PingGo\'s products are guaranteed to be genuine from suppliers and brands with competitive prices. When ordering at PingGo, you do not need to worry about quality because the products listed for sale on the floor have been carefully selected and inspected by PingGo.',
        no3: '- We serve 7 days a week.',
        no4: '- Work regulations :',
        no5: '- During work, members must follow the order and in accordance with the system\'s regulations and requirements .',
        no6: '- Pinggo.vn would like to sincerely thank the members who have contributed to accompanying Pinggo Joint Stock Company .',
    },
    revision: {
        password: 'Password',
        withdrawalPassword: 'Withdrawal password',
        withdrawalAccount: 'Withdrawal account',
        currentPassword: 'current password',
        aNewPassword: 'A new password',
        confirmYourPassword: 'Confirm your password',
        realName: 'Real name',
        userNAME: 'User name',
        bankAccount: 'Bank account',
        bankName: 'Bank name',
    },
    order: {
        all: '所有的',
        not: '未解决',
        complete: '完全的',
        frozen: '冷冻的',
    }
}