<template>
    <div>
        <topBox :title="title" />
        <!-- main -->
        <div class="h-[calc(100vh-50px)] pt-[50px] pb-[50px] overflow-y-auto  bg-local">
            <div class="w-[calc(100vw-12vw)] mx-auto  text-black">
                <!-- main box -->
                <div class="my-10 text-left  text-xs text-slate-900">

                    <div class="my-1">
                        <h1 :class="css.h1">{{ $t('revision.realName') }}</h1>
                        <input v-model="input.real_name" :class="css.input" placeholder="" />
                    </div>

                    <div class="my-5">
                        <h1 :class="css.h1">{{ $t('revision.userNAME') }}</h1>
                        <input v-model="input.bank_user" :class="css.input" placeholder="" />
                    </div>

                    <div class="my-5">
                        <h1 :class="css.h1">{{ $t('revision.bankAccount') }}</h1>
                        <input v-model="input.bank_card" :class="css.input" placeholder="" />
                    </div>

                    <div class="my-5">
                        <h1 :class="css.h1">{{ $t('revision.bankName') }}</h1>
                        <select v-model="input.bank_id" :class="css.input" >
                            <option v-for="(value, key) in banks" v-bind:key="key" :value="value.bank_id" class="text-ms">
                                {{ value.name }}
                            </option>
                        </select>
                    </div>

                    <div class="mt-0 p-4">
                        <button :class="css.but" @click="onSubmit">{{ $t('but.continue') }}</button>
                    </div>

                </div>
                <!-- /end main box -->
            </div>
        </div>
        <!-- /end main -->
        <mainMenu />  
    </div>
</template>

<script>
import axios from 'axios'
import { layer } from "vue3-layer"
import { useI18n } from 'vue-i18n'
import topBox from "../components/TopBox.vue"
import mainMenu from "../components/MainMenu.vue"
import { ref } from 'vue'
import postPages from "@/assets/js/postPages"

export default {
    components: {
        topBox,
        mainMenu
    },
    setup() {
        // 向子组件传递的参数
        const title = ref( 'x' )  
        return {
            title
        }
    },
    data() {
        return {
            lang: '',
            banks: [],
            input: {
                real_name: '',
                bank_user: '',
                bank_card: '',
                bank_id: '',
            },
            css: {
                h1: 'text-xs-xs pl-5 font-semibold text-slate-700 text-left',
                input: 'block w-full px-5 py-2 border rounded-lg bg-white shadow-lg placeholder-gray-400 text-gray-700 focus:ring focus:outline-none  text-xs-xs',
                but: 'submit-button text-xs text-white px-1 py-2 rounded-full bg-orange-500 text-blue-900 focus:ring focus:outline-none w-full text-xl font-semibold transition-colors',
            }
        }
    },
    mounted() {
        // 切换语言
        const { locale } = useI18n()  // 从国际化中取出配置项 locale 因为初始化时 用户没有指定语种 要使用系统默认的语种
        this.lang = localStorage.getItem("lang") ? localStorage.getItem("lang") : locale.value // 如果用户有选择特定语种 就使用用户指定的 不然就使用系统默认的
        locale.value = this.lang

        this.title = this.$t('page.accountsCanBeReceived') // 重置 setup 中的 title 要在重设置了语言之后

        this.getSelect()
        return {}
    },
    methods: {
        getSelect()
        {
            // 获取供选择的数据项
            // 如何打包搜索参数？  &p=${p}
            this.searchParams = `&commodity_id=${this.lang}`
            const page = postPages(this.lang, localStorage.getItem('token'), '/api/m/banks', this.ps, this.p, this.searchParams)
            // 将异步获取的数据导入 VM 数据
            page.data.then(res =>{
                console.log(res)
                // const data = toRaw(res)
                this.banks = res.data.list
            })
        },
        onSubmit() {
            axios.post(
                '/api/m/edit/bank', 
                {
                    real_name: this.input.real_name,
                    bank_user: this.input.bank_user,
                    bank_card: this.input.bank_card,
                    bank_id: this.input.bank_id,
                },
                {
                    headers: {
                        // 'Content-Type': 'multipart/form-data'
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Accept-Language': this.lang,
                        'token': localStorage.getItem('token')
                    }
                }
            )
            .then((response) =>  {
                if (0===response.data.code) {
                    layer.msg(response.data.msg)
                    this.$router.push({
                        path: '/user',
                        query: {
                            msg: ''
                        }
                    })
                } else {
                    layer.msg( this.$t('input.'+response.data.data.id) + ' ' + response.data.msg)
                }
                this.getData()
            })
            .catch(function (error) {
                console.log(error)
            })
        } // End onSubmit()
    }
}
</script>

<style>
.top-box-bg {
  background-image: url('/public/upload/600x55.png');
  background-repeat: no-repeat; /** 图片不要平铺 */
  background-size: 100% 55px; /** 因为用大一倍的图，才够清晰，但是如果不定义自适应，只能显示一半的图片。所以宽度给 100% 但高度要给它限制 */
}
</style>