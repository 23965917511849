<template>
    <div>

        <!-- 顶部工具条，不要使用背景色，因为要应用背景图。bg-white -->
        <div class="fixed left-0 top-0 h-[50px] w-full z-50">
            <!-- <div class="grid grid-cols-6 gap-2 mx-2">
                <div class="h-9">
                    <router-link to="/">
                        <svg class="w-3 h-3 mx-auto mt-2.5"  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                            <path fill="#FFFFFF" d="M34.5 239L228.9 44.7c9.4-9.4 24.6-9.4 33.9 0l22.7 22.7c9.4 9.4 9.4 24.5 0 33.9L131.5 256l154 154.8c9.3 9.4 9.3 24.5 0 33.9l-22.7 22.7c-9.4 9.4-24.6 9.4-33.9 0L34.5 273c-9.4-9.4-9.4-24.6 0-33.9z"/>
                        </svg>
                    </router-link>
                </div>
                <div class="col-span-4 pt-[15px]">
                    <h1 class="text-xs font-semibold text-white ">用户中心</h1>
                </div>
                <div class="h-9">
                    
                </div>
            </div> -->
        </div>

        <div class="h-[calc(100vh-50px)] pt-[50px] pb-[50px] overflow-y-auto focus bg-local">

            <!-- 定义左可各留百分之六的盒 -->
            <div class="w-[calc(100vw-12vw)] mx-auto h-[120px] bg-gradient">
                <div class="pt-[15px]">
                    <!-- 用户头像 按用户等级来吧 -->
                    <img class="block w-[35px] h-[35px] mx-auto border border-white" :src="`/img/avatar_2.png`" alt="user avatar" />
                </div>
                <div>
                    <span class="text-xs-xs text-white">{{api.account}}</span><span class="ml-2 text-xs-xs text-white">{{api.grade}}</span>
                </div>
                <div>
                    <span class="text-xs-xs text-[#50d71e]">{{ $t('title.inviteCode') }}</span><span class="ml-2 text-xs-xs text-white">{{ api.invite }}</span>
                </div>
                <!-- 用户头像/帐号/等级/邀请码 -->
            </div>


            <div class="w-[calc(100vw-12vw)] pt-3 mx-auto">
                <div class="relative justify-between ">
                    <div class="absolute left-0 flex items-center pt-[10px] pointer-events-none">
                        <svg class="w-[20px] h-[20px] stroke-current" fill="none" viewBox="0 0 640 512">
                            <path fill="#fe782f"  d="M497.9 225.9L286.1 14.1A48 48 0 0 0 252.1 0H48C21.5 0 0 21.5 0 48v204.1a48 48 0 0 0 14.1 33.9l211.9 211.9c18.7 18.7 49.1 18.7 67.9 0l204.1-204.1c18.7-18.7 18.7-49.1 0-67.9zM112 160c-26.5 0-48-21.5-48-48s21.5-48 48-48 48 21.5 48 48-21.5 48-48 48zm513.9 133.8L421.8 497.9c-18.7 18.7-49.1 18.7-67.9 0l-.4-.4L527.6 323.5c17-17 26.4-39.6 26.4-63.6s-9.4-46.6-26.4-63.6L331.4 0h48.7a48 48 0 0 1 33.9 14.1l211.9 211.9c18.7 18.7 18.7 49.1 0 67.9z"/>
                        </svg>
                    </div>
                    <span class="inline-block w-full pr-4 py-0 text-sm text-zinc-900 text-left px-6  text-left text-xs-xs">{{$t('text.workingCapital')}}</span>
                </div>
                <div class="text-xs-xs text-right mb-1">
                    <router-link to="/financial/details">{{$t('text.financialDetails')}}</router-link>
                </div>
               
              
                <div class="flex justify-between items-center">
                    <div class="text-xs-xs pl-6">{{ $t('text.amountOfWorkingCapital') }}</div>
                    <div class="text-xs text-orange-600">{{api.balance}} VND</div>
                </div>

                <div class="flex justify-between items-center">
                    <div class="text-xs-xs pl-6">{{ $t('text.currentFrozenBalance') }}</div>
                    <div class="text-xs text-orange-600">{{api.frozen}} VND</div>
                </div>
                
            </div>


            <div class="w-[calc(100vw-12vw)] pt-3 mx-auto">
                <div class="grid grid-cols-2 gap-2 pb-2">
                    <div class="pl-6">
                        <button
                            class="submit-button px-1 py-1 rounded-full bg-orange-500 text-white focus:ring focus:outline-none w-full text-xs font-semibold transition-colors"
                            x-bind:disabled="!isValid"
                            x-on:click="onSubmit()"
                            @click="$router.push('/recharge')"
                        >
                            {{ $t('but.recharge') }}
                        </button>
                    </div>
                    <div class="pl-6">
                        <button
                            v-show="api.bank_id"
                            class="submit-button px-1 py-1 rounded-full bg-orange-500 text-white focus:ring focus:outline-none w-full text-xs font-semibold transition-colors"
                            @click="$router.push('/withdraw')"
                        >
                            {{ $t('but.withdraw') }}
                        </button>

                        <button
                            v-show="!api.bank_id"
                            class="submit-button px-1 py-1 rounded-full bg-orange-500 text-white focus:ring focus:outline-none w-full text-xs font-semibold transition-colors"
                            @click="$router.push('/withdraw/account')"
                        >
                            {{ $t('but.withdraw') }}
                        </button>

                    </div>
                </div>
            </div>


            <div class="w-[calc(100vw-12vw)] pt-3 mx-auto">
                <div class="grid grid-cols-2 gap-0 pb-2">
                    <div class="text-xs-xs text-left  pl-6">
                        <div>{{ $t('text.orderToday') }}</div>
                        <div class="text-orange-600">0</div>
                    </div>
                    <div class="text-xs-xs text-right">
                        <div>{{ $t('text.roseToday') }}</div>
                        <div class="text-orange-600">0</div>
                    </div>
                    <div class="text-xs-xs text-left  pl-6">
                        <div>{{ $t('text.allOrders') }}</div>
                        <div class="text-orange-600">0</div>
                    </div>
                    <div class="text-xs-xs text-right">
                        <div>{{ $t('text.totalCommission') }}</div>
                        <div class="text-orange-600">0</div>
                    </div>
                </div>
            </div>


            <div class="w-[calc(100vw-12vw)] pt-3 mx-auto">
                <div class="relative justify-between">
                    
                    <div class="absolute left-0 flex items-center pt-[10px] pointer-events-none">
                        <svg class="w-[20px] h-[20px] stroke-current" fill="none" viewBox="0 0 640 512">
                            <path fill="#fe782f"  d="M497.9 225.9L286.1 14.1A48 48 0 0 0 252.1 0H48C21.5 0 0 21.5 0 48v204.1a48 48 0 0 0 14.1 33.9l211.9 211.9c18.7 18.7 49.1 18.7 67.9 0l204.1-204.1c18.7-18.7 18.7-49.1 0-67.9zM112 160c-26.5 0-48-21.5-48-48s21.5-48 48-48 48 21.5 48 48-21.5 48-48 48zm513.9 133.8L421.8 497.9c-18.7 18.7-49.1 18.7-67.9 0l-.4-.4L527.6 323.5c17-17 26.4-39.6 26.4-63.6s-9.4-46.6-26.4-63.6L331.4 0h48.7a48 48 0 0 1 33.9 14.1l211.9 211.9c18.7 18.7 18.7 49.1 0 67.9z"/>
                        </svg>
                    </div>
                    <span class="inline-block w-full pr-4 py-0 text-sm text-zinc-900 text-left px-6  text-left text-xs-xs">
                        {{$t('text.quickAction')}}
                    </span>
                </div>
                <div class="grid grid-cols-3 gap-0 pb-2">
                    <div class="text-xs-xs  pl-6 pt-5">
                        <router-link to="/order">
                            <svg class="w-[30px] h-[30px] mx-auto" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                <path fill="#fe782f"  d="M464 32H48C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48 48h416c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48zm-6 400H54a6 6 0 0 1 -6-6V86a6 6 0 0 1 6-6h404a6 6 0 0 1 6 6v340a6 6 0 0 1 -6 6zm-42-92v24c0 6.6-5.4 12-12 12H204c-6.6 0-12-5.4-12-12v-24c0-6.6 5.4-12 12-12h200c6.6 0 12 5.4 12 12zm0-96v24c0 6.6-5.4 12-12 12H204c-6.6 0-12-5.4-12-12v-24c0-6.6 5.4-12 12-12h200c6.6 0 12 5.4 12 12zm0-96v24c0 6.6-5.4 12-12 12H204c-6.6 0-12-5.4-12-12v-24c0-6.6 5.4-12 12-12h200c6.6 0 12 5.4 12 12zm-252 12c0 19.9-16.1 36-36 36s-36-16.1-36-36 16.1-36 36-36 36 16.1 36 36zm0 96c0 19.9-16.1 36-36 36s-36-16.1-36-36 16.1-36 36-36 36 16.1 36 36zm0 96c0 19.9-16.1 36-36 36s-36-16.1-36-36 16.1-36 36-36 36 16.1 36 36z"/>
                            </svg>
                            <div>{{ $t('text.order') }}</div>
                        </router-link>
                    </div>
                    <div class="text-xs-xs pl-6  pt-5">
                        <router-link to="/revision">
                            <svg  class="w-[30px] h-[30px] mx-auto" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                                <path  fill="#fe782f" d="M402.6 83.2l90.2 90.2c3.8 3.8 3.8 10 0 13.8L274.4 405.6l-92.8 10.3c-12.4 1.4-22.9-9.1-21.5-21.5l10.3-92.8L388.8 83.2c3.8-3.8 10-3.8 13.8 0zm162-22.9l-48.8-48.8c-15.2-15.2-39.9-15.2-55.2 0l-35.4 35.4c-3.8 3.8-3.8 10 0 13.8l90.2 90.2c3.8 3.8 10 3.8 13.8 0l35.4-35.4c15.2-15.3 15.2-40 0-55.2zM384 346.2V448H64V128h229.8c3.2 0 6.2-1.3 8.5-3.5l40-40c7.6-7.6 2.2-20.5-8.5-20.5H48C21.5 64 0 85.5 0 112v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V306.2c0-10.7-12.9-16-20.5-8.5l-40 40c-2.2 2.3-3.5 5.3-3.5 8.5z"/>
                            </svg>
                            <div>{{ $t('text.revision') }}</div>
                        </router-link>
                    </div>
                    <div class="text-xs-xs  pl-6  pt-5">
                        <router-link to="/guide">
                            <svg  class="w-[30px] h-[30px] mx-auto" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                                <path fill="#fe782f" d="M224 136V0H24C10.7 0 0 10.7 0 24v464c0 13.3 10.7 24 24 24h336c13.3 0 24-10.7 24-24V160H248c-13.2 0-24-10.8-24-24zm64 236c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-8c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12v8zm0-64c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-8c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12v8zm0-72v8c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-8c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12zm96-114.1v6.1H256V0h6.1c6.4 0 12.5 2.5 17 7l97.9 98c4.5 4.5 7 10.6 7 16.9z"/>
                            </svg>
                            <div>{{ $t('text.guide') }}</div>
                        </router-link>
                    </div>
                    <div class="text-xs-xs  pl-6 pt-3">
                        <router-link to="/serve">
                            <svg class="w-[30px] h-[30px] mx-auto"  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                <path  fill="#fe782f"  d="M480 160H32c-17.7 0-32-14.3-32-32V64c0-17.7 14.3-32 32-32h448c17.7 0 32 14.3 32 32v64c0 17.7-14.3 32-32 32zm-48-88c-13.3 0-24 10.7-24 24s10.7 24 24 24 24-10.7 24-24-10.7-24-24-24zm-64 0c-13.3 0-24 10.7-24 24s10.7 24 24 24 24-10.7 24-24-10.7-24-24-24zm112 248H32c-17.7 0-32-14.3-32-32v-64c0-17.7 14.3-32 32-32h448c17.7 0 32 14.3 32 32v64c0 17.7-14.3 32-32 32zm-48-88c-13.3 0-24 10.7-24 24s10.7 24 24 24 24-10.7 24-24-10.7-24-24-24zm-64 0c-13.3 0-24 10.7-24 24s10.7 24 24 24 24-10.7 24-24-10.7-24-24-24zm112 248H32c-17.7 0-32-14.3-32-32v-64c0-17.7 14.3-32 32-32h448c17.7 0 32 14.3 32 32v64c0 17.7-14.3 32-32 32zm-48-88c-13.3 0-24 10.7-24 24s10.7 24 24 24 24-10.7 24-24-10.7-24-24-24zm-64 0c-13.3 0-24 10.7-24 24s10.7 24 24 24 24-10.7 24-24-10.7-24-24-24z"/>
                            </svg>
                            <div>{{ $t('text.serve') }}</div>
                        </router-link>
                    </div>
                    <div class="text-xs-xs  pl-6 pt-3">
                        <router-link to="/company/profile">
                            <svg class="w-[30px] h-[30px] mx-auto"  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                <path fill="#fe782f"  d="M128 148v-40c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12zm140 12h40c6.6 0 12-5.4 12-12v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12zm-128 96h40c6.6 0 12-5.4 12-12v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12zm128 0h40c6.6 0 12-5.4 12-12v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12zm-76 84v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12zm76 12h40c6.6 0 12-5.4 12-12v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12zm180 124v36H0v-36c0-6.6 5.4-12 12-12h19.5V24c0-13.3 10.7-24 24-24h337c13.3 0 24 10.7 24 24v440H436c6.6 0 12 5.4 12 12zM79.5 463H192v-67c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v67h112.5V49L80 48l-.5 415z"/>
                            </svg>
                            <div>{{ $t('text.companyProfile') }}</div>
                        </router-link>
                    </div>
                    <div class="text-xs-xs  pl-6  pt-3">
                        <!-- <router-link to="/online/service">
                            <svg class="w-[30px] h-[30px] mx-auto" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                <path fill="#fe782f" d="M288 130.5V112h16c8.8 0 16-7.2 16-16V80c0-8.8-7.2-16-16-16h-96c-8.8 0-16 7.2-16 16v16c0 8.8 7.2 16 16 16h16v18.5C115.5 146.1 32 239.2 32 352h448c0-112.8-83.5-205.9-192-221.5zM496 384H16c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h480c8.8 0 16-7.2 16-16v-32c0-8.8-7.2-16-16-16z"/>
                            </svg>
                            <div>{{ $t('text.onlineService') }}</div>
                        </router-link> -->
                        <div @click="out">
                            <svg class="w-[30px] h-[30px] mx-auto" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                <path fill="#fe782f" d="M288 130.5V112h16c8.8 0 16-7.2 16-16V80c0-8.8-7.2-16-16-16h-96c-8.8 0-16 7.2-16 16v16c0 8.8 7.2 16 16 16h16v18.5C115.5 146.1 32 239.2 32 352h448c0-112.8-83.5-205.9-192-221.5zM496 384H16c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h480c8.8 0 16-7.2 16-16v-32c0-8.8-7.2-16-16-16z"/>
                            </svg>
                            <div>{{ $t('page.out') }}</div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <!-- <div class="h-10">
            &nbsp;
        </div> -->

        <mainMenu />  
    </div>
</template>

<script>
import { useI18n } from 'vue-i18n'
import mainMenu from "../components/MainMenu.vue"
import axios from 'axios'
import { layer } from "vue3-layer"
export default {
    components: {
        mainMenu
    },
    setup() {
        // console.log( `token : ${localStorage.getItem('token')}` )
        // if ( !localStorage.getItem('token') ) {
        //     console.log('连令牌都没有，还不去登录？')
        // }
    },
    data() {
        return {
            lang: '',
            api: {
                account: null,
                uid: 0,
                invite: null,
                bank_id: null,
            },
        }
    },
    mounted() {
        // 切换语言
        const { locale } = useI18n()  // 从国际化中取出配置项 locale 因为初始化时 用户没有指定语种 要使用系统默认的语种
        this.lang = localStorage.getItem("lang") ? localStorage.getItem("lang") : locale.value // 如果用户有选择特定语种 就使用用户指定的 不然就使用系统默认的
        locale.value = this.lang
        // 通过 Token 去调取帐号相关信息，即时。
        this.getInfo()
        return {}
    },
    methods: {
        doClick() {
            // console.log('ok')
        },
        onSubmit() {
            this.$route.to('/recharge')
        },
        // 得到这个用户的基本信息
        getInfo()
        {
            // console.log('得到这个用户的基本信息')
            axios.post(
                '/api/m/info/user', 
                {
                },
                {
                    headers: {
                        // 'Content-Type': 'multipart/form-data'
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Accept-Language': this.lang,
                        'token': localStorage.getItem('token')
                    }
                }
            )
            .then((response) =>  {
                // console.log(response.data)
                if (0===response.data.code) {
                    this.api.bank_id = response.data.data.bank_id
                    this.api.account = response.data.data.account
                    this.api.grade = `VIP${response.data.data.grade}` 
                    this.api.uid = response.data.data.uid
                    this.api.invite = 999999 - response.data.data.uid
                    this.api.balance = response.data.data.balance
                    this.api.frozen = response.data.data.frozen
                } else {
                    // let word = response.data.data.id
                    // let key = word.charAt(0).toUpperCase() + word.slice(1)
                    // // 转成国际化 后端转提示语 前端转表单名称
                    // let msg = this.$t(`input.${key}`) + ' ' + response.data.msg 
                    layer.msg(response.data.msg)
                }
            })
            .catch(function (error) {
                console.log(error)
            })
        },
        // End 得到这个用户的基本信息
        out() {
            // localStorage.removeItem('token')
            axios.post(
                '/api/m/out', 
                {

                },
                {
                    headers: {
                        // 'Content-Type': 'multipart/form-data'
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Accept-Language': this.lang,
                        'token': localStorage.getItem('token')
                    }
                }
            )
            .then((response) =>  {
                if (0===response.data.code) {
                    localStorage.removeItem('token')
                    // this.$router.go(0) // 刷新本页
                    // 跳转
                    this.$router.push({
                        path: '/login',
                        query: {
                            msg: 'account out'
                        }
                    })
                } else {
                    layer.msg( response.data.msg )
                }
            })
            .catch(function (error) {
                console.log(error)
            })
        }, // End out() 退出系统
    }
}
</script>

<style>
.focus {
    background-image: url('/public/upload/600.png');
    background-repeat: no-repeat; /** 图片不要平铺 */
    background-size: 100% 190px; /** 因为用大一倍的图，才够清晰，但是如果不定义自适应，只能显示一半的图片。所以宽度给 100% 但高度要给它限制 */
}
.bg-gradient {
    background: linear-gradient(270deg, #fe702e 0%, #f7395f 100%); 
}
</style>