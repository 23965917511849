<template>
    <div class="maxW fixed left-0 top-0 h-[50px] w-full z-50 top-box-bg">
        <div class="grid grid-cols-6 gap-2 mx-2">
            <div class="h-9">
                <router-link to="#" @click="$router.back()">
                    <svg class="w-3 h-3 mx-auto mt-2.5"  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                        <path fill="#FFFFFF" d="M34.5 239L228.9 44.7c9.4-9.4 24.6-9.4 33.9 0l22.7 22.7c9.4 9.4 9.4 24.5 0 33.9L131.5 256l154 154.8c9.3 9.4 9.3 24.5 0 33.9l-22.7 22.7c-9.4 9.4-24.6 9.4-33.9 0L34.5 273c-9.4-9.4-9.4-24.6 0-33.9z"/>
                    </svg>
                </router-link>
            </div>
            <div class="col-span-4 pt-[15px] ">
                <h1 class="text-xs font-semibold text-white omit text-nowrap">{{ title }}</h1>
            </div>
            <div class="h-9">
                
            </div>
        </div>
    </div>
</template>

<script>

export default {
    //接收参数
    props: { 
        title: String 
    },
    setup(props, context) {
        //props传递的参数    name:'我是父组件传递的信息2'
        console.log(props);
        //attrs传递的参数    msg1: '我是父组件传递的信息1'
        console.log({ ...context.attrs });
    }
}
</script>

<style>
    .maxW {max-width: 600px;}
</style>