export default {
    lang: {
        'vi-vn': 'Tiếng Việt',
        'zh-cn': '中文'
    },
    page: {
        order: '订单',
        revision: '修订',
        guide: '指导',
        serve: '服务',
        companyProfile: '公司介绍',
        onlineService: '在线服务',
        financialDetails: '财务详情',
        recharge: '充值',
        withdraw: '提款',
        accountsCanBeReceived: '可以收到帐号',
        password: '修改登录密码',
        passwordFund: '修改资金密码',
        SubmitOrder: '提交订单',
        product: '提交订单',
        OrderDetail: '订单详情',
        out: '退出',
    },
    side: {
        main: '首页',
        trade: '交易管理',
        tradeOrder: '订单列表',
        tradeRecharge: '充值管理',
        tradeWithdraw: '提现管理',
        tradeControl: '交易控制',
        tradeAmount: '金额调整',
        tradeStatistics: '统计报表',
        tradeReport: '代理报表',
        tradeReportDaily: '代理日报表',
        tradeReportMonthly: '代理月报表',
        commodity: '商品管理',
        commodityList: '商品列表',
        commoditySort: '商品分类',
        member: '会员管理',
        memberList: '会员列表',
        memberSort: '会员等级',
        memberLevel: '会中层级',
        content: '内容管理',
        contentNotice: '公告管理',
        contentText: '文本管理',
        contentAd: '首页轮播图',
        contentLogo: 'LOGO',
        power: '权限管理',
        powerList: '访问权限',
        powerAdmin: '系统用户',
        system: '系统管理',
        systemMenu: '系统菜单管理',
        systemParameter: '系统参数配置',
        systemLog: '系统操作日志'
    },
    but: {
        login: '登录',
        register: '注册',
        continue: '继续',
        recharge: '充值',
        withdraw: '提款',
    },
    footer: {
        homePage: '主页',
        product: '产品',
        mine: '矿',
    },
    title: {
        account: '帐号',
        password: '密码',
        confirmPassword: '确认密码',
        inviteCode: '邀请代码',
        FundPassword: '资金密码',
    },
    placeholder: {
        account: '帐号（数字和字母的组合）',
        password: '请输入您的密码',
        agree: '我同意',
        userAgreement: '用户协议',
        user: '请输入您的电话号码',
        confirmPassword: '请重新输入你的密码',
        invitation: '输入邀请码',
        rechargeAmount: '输入充值金额',
        withdrawalAmount: '输入提款金额 (VND)',
        FundPassword: '输入资金密码 (VND)',
        firstSettingFund: '第一次设置可以不填',
    },
    text: {
        workingCapital: '营运资金',
        quickAction: '快速操作',
        amountOfWorkingCapital: '营运资金金额',
        currentFrozenBalance: '当前冻结余额',
        orderToday: '今天订购',
        roseToday: '今天玫瑰',
        allOrders: '所有订单',
        totalCommission: '佣金总额',
        order: '订单',
        revision: '修订',
        guide: '指导',
        serve: '服务',
        companyProfile: '公司介绍',
        onlineService: '在线服务',
        financialDetails: '财务详情',
        rechargeAnnouncement: '注意：由于存款信息金额太大，存款前请务必仔细核对您的账户卡号。 该平台会不时更改帐号。 如有任何疑问，请点击在线客服！',
        rechargeLimit: '网上存款1（5万至3亿）',
        depositMethodSelect: '选择存款方式',
        depositMethod: '存款方式',
        serveText: '恭喜您，您已成功登录！',
        tipRecharge: '请在15分钟内完成银行转帐',
        AccountBalance: '账户余额',
        withdrawalMin: '最低提款金额：50000 VND',
        withdrawalMax: '最大提款金额：999999999 VND',
        WaitingForAllocation: '等待系统分配',
    },
    guideText: {
        no1: '- 网上购物带来便捷、主动、多样的选择，采购、销售、安全支付、专业配送的支持服务，Pinggo.vn 不断努力和改进，以满足消费者的购物需求。',
        no2: '- PingGo 的产品保证来自供应商和品牌的正品，价格具有竞争力。 在PingGo订购时，您无需担心质量，因为本场出售的产品都是经过 PingGo 精心挑选和检查的。',
        no3: '- 我们每周服务 7 天。',
        no4: '- 工作规定：',
        no5: '- 工作时，成员必须遵守秩序，遵守制度的规定和要求。',
        no6: '- Pinggo.vn 衷心感谢为陪伴 Pinggo 股份公司做出贡献的会员。',
    },
    revision: {
        password:'密码',
        withdrawalPassword: '提现密码',
        withdrawalAccount: '提款账户',
        currentPassword: '当前密码',
        aNewPassword: '新密码',
        confirmYourPassword: '确认你的密码',
        realName: '真正的名字',
        userNAME: '用户名',
        bankAccount: '银行账户',
        bankName: '银行名',
        rechargeAmount: '充值金额',
        CompleteRecharge: '完成充值',
        withdrawalAmount: '提款金额',
    },
    order: {
        all: '所有的',
        not: '未解决',
        complete: '完全的',
        frozen: '冷冻的',
    },
    input: {
        Account: '用户名',
        Passkey: '密码',
        real_name: '真实姓名',
        bank_user: '用户名',
        bank_card: '银行账户',
        bank_id: '银行名',
        amount: '提现金额',
        pass_pay: '资金密码',
        pass_login: '当前密码',
        new: '新密码',
        confirm: '确认密码',
    },
    pageTxt: {
        order: {
            tab1: '全部',
            tab2: '等待',
            tab3: '完成',
            tab4: '冻结',
            awaiting: '等待分配',
        },
        orderDetail: {
            orderValue : '订单价值',
            profitMoney: '佣金',
            amountToBeReturned: '要退回的金额',
            codeOrders: '编号',
            receivingTime: '时间',
            orderStatus: '订单状态',
            submit: '付款',
        }
    }
}