import { createApp } from 'vue'
import Main from './MyMessage'

let timer = null
let app = null

const showMessage = function (options) {
  clearTimeout(timer)

  if (!app) {
    app = createApp(Main).mount('#app2')
  }

  app.showMessage = true
  Object.assign(app, options)
  timer = setTimeout(() => {
    app.showMessage = false
  }, 1500)
}

const Message = function (options) {
  if (!document.getElementById('app2')) {
    const div = document.createElement('div')
    div.id = 'app2'
    document.body.appendChild(div)
  }

  showMessage(options)
}

;['success', 'error'].forEach(type => {
  Message[type] = options => {
    const _opt = {
      msg: options,
      type
    }

    Message(_opt)
  }
})

export default Message




