
// 进口路由
import { createRouter, createWebHistory } from "vue-router"
// 进口仪表板视图模板
import home from '../pages/IndexHome'
import login from '../pages/LoginUser'
import register from '../pages/RegisterUser'
// 登录后的模板
import user from '../pages/UserMain'
import OrderList from '../pages/OrderList'
import MainProduct from '@/pages/MainProduct'
import UserRevision from '../pages/UserRevision'
import UserGuide from '../pages/UserGuide'
import CompanyProfile from '../pages/CompanyProfile'
import UserServe from '../pages/UserServe'
import OnlineService from '../pages/OnlineService'
import FinancialDetails from '../pages/FinancialDetails'
import UserRecharge from '../pages/UserRecharge'
import UserRecharge2 from '../pages/UserRecharge2'
import UserWithdraw from '../pages/UserWithdraw'
import EditPassword from '../pages/EditPassword'
import WithdrawalPassword from '../pages/WithdrawalPassword'
import WithdrawalAccount from '../pages/WithdrawalAccount'
// 抢单成功
import OrderSuccess from '../pages/OrderSuccess'
// 订单详情
import DetailOrder from '../pages/DetailOrder'


import i18n from '../language'


const routes = [
    {
        name: 'Home',
        // 路由的 URL 字串
        path: '/',
        component: home,
        meta: {
            // 要切换语言，这个配置中的语言，可以要写入缓存。
            // 不然视图模板可以切到别的语言，但是路由在视图之前，这里是不会动态切换的。
            title: i18n.global.messages.value[ localStorage.getItem("lang") ?? 'vi-vn' ]['page']['order']
        }
    },
    {
        name: 'Login',
        // 路由的 URL 字串
        path: '/login',
        component: login,
        meta: {
            // 要切换语言，这个配置中的语言，可以要写入缓存。
            // 不然视图模板可以切到别的语言，但是路由在视图之前，这里是不会动态切换的。
            title: i18n.global.messages.value[ localStorage.getItem("lang") ?? 'vi-vn' ]['side']['main']
        }
    },
    {
        name: 'Register',
        path: '/register',
        component: register,
        meta: {
            // 要切换语言，这个配置中的语言，可以要写入缓存。
            // 不然视图模板可以切到别的语言，但是路由在视图之前，这里是不会动态切换的。
            title: i18n.global.messages.value[ localStorage.getItem("lang") ?? 'vi-vn' ]['side']['main']
        }
    },
    {
        name: 'MainProduct',
        path: '/product',
        component: MainProduct,
        meta: {
            title: i18n.global.messages.value[ localStorage.getItem("lang") ?? 'vi-vn' ]['page']['product']
        }
    },
    {
        name: 'User',
        path: '/user',
        component: user,
        meta: {
            // 要切换语言，这个配置中的语言，可以要写入缓存。
            // 不然视图模板可以切到别的语言，但是路由在视图之前，这里是不会动态切换的。
            title: i18n.global.messages.value[ localStorage.getItem("lang") ?? 'vi-vn' ]['side']['main']
        }
    },
    {
        name: 'Order',
        path: '/order',
        component: OrderList,
        meta: {
            // 要切换语言，这个配置中的语言，可以要写入缓存。
            // 不然视图模板可以切到别的语言，但是路由在视图之前，这里是不会动态切换的。
            title: i18n.global.messages.value[ localStorage.getItem("lang") ?? 'vi-vn' ]['page']['order']
        }
    },
    {
        name: 'UserRevision',
        path: '/revision',
        component: UserRevision,
        meta: {
            title: i18n.global.messages.value[ localStorage.getItem("lang") ?? 'vi-vn' ]['page']['revision']
        }
    },
    {
        name: 'UserGuide',
        path: '/guide',
        component: UserGuide,
        meta: {
            title: i18n.global.messages.value[ localStorage.getItem("lang") ?? 'vi-vn' ]['page']['guide']
        }
    },
    {
        name: 'CompanyProfile',
        path: '/company/profile',
        component: CompanyProfile,
        meta: {
            title: i18n.global.messages.value[ localStorage.getItem("lang") ?? 'vi-vn' ]['page']['companyProfile']
        }
    },
    {
        name: 'UserServe',
        path: '/serve',
        component: UserServe,
        meta: {
            title: i18n.global.messages.value[ localStorage.getItem("lang") ?? 'vi-vn' ]['page']['serve']
        }
    },
    {
        name: 'OnlineService',
        path: '/online/service',
        component: OnlineService,
        meta: {
            title: i18n.global.messages.value[ localStorage.getItem("lang") ?? 'vi-vn' ]['page']['onlineService']
        }
    },
    {
        name: 'FinancialDetails',
        path: '/financial/details',
        component: FinancialDetails,
        meta: {
            title: i18n.global.messages.value[ localStorage.getItem("lang") ?? 'vi-vn' ]['page']['financialDetails']
        }
    },
    {
        name: 'UserRecharge',
        path: '/recharge',
        component: UserRecharge,
        meta: {
            title: i18n.global.messages.value[ localStorage.getItem("lang") ?? 'vi-vn' ]['page']['recharge']
        }
    },
    {
        name: 'UserRecharge2',
        path: '/recharge2',
        component: UserRecharge2,
        meta: {
            title: i18n.global.messages.value[ localStorage.getItem("lang") ?? 'vi-vn' ]['page']['recharge']
        }
    },
    {
        name: 'UserWithdraw',
        path: '/withdraw',
        component: UserWithdraw,
        meta: {
            title: i18n.global.messages.value[ localStorage.getItem("lang") ?? 'vi-vn' ]['page']['withdraw']
        }
    },
    {
        name: 'EditPassword',
        path: '/edit/password',
        component: EditPassword,
        meta: {
            title: i18n.global.messages.value[ localStorage.getItem("lang") ?? 'vi-vn' ]['page']['password']
        }
    },
    {
        name: 'WithdrawalPassword',
        path: '/withdraw/password',
        component: WithdrawalPassword,
        meta: {
            title: i18n.global.messages.value[ localStorage.getItem("lang") ?? 'vi-vn' ]['page']['withdraw']
        }
    },
    {
        name: 'WithdrawalAccount',
        path: '/withdraw/account',
        component: WithdrawalAccount,
        meta: {
            title: i18n.global.messages.value[ localStorage.getItem("lang") ?? 'vi-vn' ]['page']['withdraw']
        }
    },
    {
        name: 'OrderSuccess',
        path: '/order/success',
        component: OrderSuccess,
        meta: {
            title: i18n.global.messages.value[ localStorage.getItem("lang") ?? 'vi-vn' ]['page']['OrderSuccess']
        }
    },
    {
        name: 'DetailOrder',
        path: '/order/detail',
        component: DetailOrder,
        meta: {
            title: i18n.global.messages.value[ localStorage.getItem("lang") ?? 'vi-vn' ]['page']['OrderDetail']
        }
    },

]

const router = Router()
export default router
function Router() {
    // console.log( localStorage.getItem("lang") ) 
    // console.log( i18n.global.locale.value )
    const router = new createRouter({
        history: createWebHistory(),
        routes
    })
    return router
}